import { Outlet } from 'react-router-dom';
import TabsPanel from './TabsPanel/TabsPanel';
import { NewObjectDefinition } from './companyDefinitionContentController';
import { styled } from 'styled-components';
import { NoObjectDefinitionsContent } from './NoObjectDefinitionsContent/NoObjectDefinitionsContent';
import { useOnOff } from '../../common/hooks/useOnOff';
import { ObjectDefinitionsSelectorModal } from './ObjectDefinitionsSelectorModal/ObjectDefinitionsSelectorModal';
import { useCompanyDefinitionContentController } from './companyDefinitionContentController';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from '@mui/material';
import { SpecifyLabelsModal } from './ObjectDefinitionsSelectorModal/SpecifyLabelsModal';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const StyledBackdrop = styled(Backdrop)`
    z-index: ${({ theme }) => theme.zIndex.drawer + 1} !important;
    // TODO: change to theme color, waiting for #BMG-590
    background-color: #ffffff66 !important;
    pointer-events: auto !important;
`;

const CompanyDefinitionContent = () => {
    const { tabs, isLoading, rows, createDefaultRows, control, saveChanges, isDirty, isValid } =
        useCompanyDefinitionContentController();
    const modalDisplayControl = useOnOff();
    const specifyLabelsModalDisplayControl = useOnOff();

    const handleBackButton = () => {
        specifyLabelsModalDisplayControl.setOff();
        modalDisplayControl.setOn();
    };

    const renderLoading = () => (
        <StyledBackdrop open={isLoading} aria-label="loading-overlay">
            <CircularProgress />
        </StyledBackdrop>
    );

    const renderNoObjectDefinitions = () => (
        <NoObjectDefinitionsContent openObjectTypeSelectionModal={modalDisplayControl.setOn} />
    );

    const renderObjectDefinitionSelectorModal = () => (
        <ObjectDefinitionsSelectorModal
            isOpen={modalDisplayControl.isOn}
            close={modalDisplayControl.setOff}
            nextButton={handleNextButton}
        />
    );

    const renderObjectLabelingModal = () => (
        <SpecifyLabelsModal
            isOpen={specifyLabelsModalDisplayControl.isOn}
            close={specifyLabelsModalDisplayControl.setOff}
            backButton={handleBackButton}
            formControl={control}
            rows={rows || []}
            saveChanges={handleSaveChanges}
            isDirty={isDirty}
            isValid={isValid}
        ></SpecifyLabelsModal>
    );

    const handleNextButton = (newObjectDefinitions: NewObjectDefinition[]) => {
        modalDisplayControl.setOff();
        specifyLabelsModalDisplayControl.setOn();
        createDefaultRows(newObjectDefinitions);
    };

    const handleSaveChanges = () => {
        saveChanges(specifyLabelsModalDisplayControl);
    };

    return (
        <>
            {isLoading && renderLoading()}
            {!isLoading && tabs.length <= 0 && renderNoObjectDefinitions()}
            {!isLoading && tabs.length > 0 && (
                <Container>
                    <TabsPanel tabs={tabs} handleManageObjectsClick={modalDisplayControl.setOn} />
                    <Outlet />
                </Container>
            )}
            {renderObjectDefinitionSelectorModal()}
            {rows && renderObjectLabelingModal()}
        </>
    );
};

export default CompanyDefinitionContent;
