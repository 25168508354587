export const opportunitiesSF = [
    {
        name: 'external_record_url',
        label: 'Record URL (View Only)',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: true,
        display_type: 'AS_DETAIL',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'Name',
        label: 'Opportunity Name',
        isMandatory: false,
        isSearchable: true,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        display_type: 'AS_TITLE',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'Description',
        label: 'Description',
        isMandatory: false,
        isSearchable: true,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        display_type: 'AS_TITLE',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'StageName',
        label: 'Stage',
        isMandatory: false,
        isSearchable: false,
        isFilterable: true,
        isColorizable: true,
        isViewOnly: false,
        displayType: 'AS_DETAIL',
        type: 'picklist',
        options: [
            {
                label: 'No Value Selected',
                value: 'BADGER_NULL_OPTION',
                order: -1,
            },
        ],
    },
    {
        name: 'Amount',
        label: 'Amount',
        isMandatory: false,
        isSearchable: true,
        isFilterable: true,
        isColorizable: false,
        isViewOnly: false,
        display_type: 'AS_TITLE',
        type: 'numeric',
        decimals: 0,
    },
    {
        name: 'CloseDate',
        label: 'Close Date',
        isMandatory: false,
        isSearchable: false,
        isFilterable: true,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_SUBTITLE',
        type: 'datetime',
    },
];
