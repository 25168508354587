import { ColumnType } from '../defs';

export const ROW_COLUMN_SEPARATOR = '//';

export type Column = {
    name: string;
    label: string;
    type: ColumnType.TEXT;
    width: number;
    editable: boolean;
};
export const SPECIFY_LABELS_MODAL_COLUMNS: Column[] = [
    {
        name: 'badgerApiName',
        label: 'CRM/Badger API Name',
        type: ColumnType.TEXT,
        width: 270,
        editable: false,
    },
    {
        name: 'crmLabel',
        label: 'CRM Label',
        type: ColumnType.TEXT,
        width: 270,
        editable: false,
    },
    {
        name: 'badgerLabel',
        label: 'Badger Label*',
        type: ColumnType.TEXT,
        width: 300,
        editable: true,
    },
    {
        name: 'badgerLabelPlural',
        label: 'Plural Badger Label*',
        type: ColumnType.TEXT,
        width: 300,
        editable: true,
    },
];

export type ObjectLabelRow = {
    uuid: string;
    badgerApiName: string;
    crmLabel: string;
    badgerLabel: string;
    badgerLabelPlural: string;
    [key: string]: string;
};
