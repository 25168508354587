import { Alignments, Button, ButtonIcons, ButtonTypes, Icon, SegmentedControls } from 'design-system';
import { TabDetails, useTabsPanelController } from './tabsPanelController';
import { styled } from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 30px;
`;

interface props {
    tabs: TabDetails[];
    handleManageObjectsClick: () => void;
}

const StyledButton = styled(Button)`
    padding: 0 10px;
`;

const ButtonContainer = styled.div`
    width: 300px;
    display: flex;
    flex-wrap: nowrap;
`;

const TabsPanel = ({ tabs, handleManageObjectsClick: addObjects }: props) => {
    const { selectedSegment, onSegmentSelection } = useTabsPanelController(tabs);

    return (
        <Container>
            <SegmentedControls
                selectedSegment={selectedSegment}
                onChange={onSegmentSelection}
                segmentsData={tabs.map((segment: TabDetails) => ({
                    label: segment.label,
                    value: segment.pathIdentifier,
                }))}
                variant="scrollable"
                size="large"
            />
            <ButtonContainer>
                <StyledButton
                    type={ButtonTypes.TEXT}
                    icon={<Icon icon={ButtonIcons.EDIT} />}
                    alignment={Alignments.ROW}
                    onClick={addObjects}
                >
                    Manage objects
                </StyledButton>
            </ButtonContainer>
        </Container>
    );
};

export default TabsPanel;
