import { skipToken } from '@reduxjs/toolkit/query/react';
import {
    useGetIntegrationConfigurationQuery,
    useUpdateIntegrationConfigurationMutation,
} from '../../store/api/integrationConfiguration/IntegrationConfigurationAPI';
import { useSelectCompany } from './useSelectCompany';
import { IntegrationConfiguration } from '../../domain/IntegrationConfiguration/IntegrationConfiguration';

const useIntegrationConfigurationRepository = () => {
    const { selectedCompanyId } = useSelectCompany();
    const {
        data: integrationConfiguration,
        isLoading,
        isError,
    } = useGetIntegrationConfigurationQuery(selectedCompanyId ?? skipToken);

    const [updateIntegrationConfiguration, info] = useUpdateIntegrationConfigurationMutation();
    const editIntegrationConfiguration = (newIntegrationConfiguration: IntegrationConfiguration) => {
        if (selectedCompanyId) {
            updateIntegrationConfiguration({
                companyId: selectedCompanyId,
                integrationConfiguration: newIntegrationConfiguration,
            });
        }
    };

    return {
        integrationConfiguration,
        isLoading,
        isLoadError: isError,
        editIntegrationConfiguration,
        isSaving: info.isLoading,
    };
};

export { useIntegrationConfigurationRepository };
