import { Dialog, loadFontAttributes } from 'design-system';
import styled from 'styled-components';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ROW_COLUMN_SEPARATOR } from '../defs';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TextTypeField from '../ObjectDefinitionsTable/TextTypeField/TextTypeField';
import { Control } from 'react-hook-form/dist/types';
import { Column, ObjectLabelRow, SPECIFY_LABELS_MODAL_COLUMNS } from './defs';
import React from 'react';

const StyledTableCell = styled(TableCell)<{ $width: number; $align: string; $hidden?: boolean }>`
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh};
    width: ${({ $width }) => $width}px !important;
    min-width: ${({ $width }) => $width}px !important;
    text-align: ${({ $align }) => $align} !important;

    &.MuiTableCell-root {
        ${({ $hidden }) => $hidden && 'display: none !important;'};
    }
`;

const HeaderTableCell = styled(StyledTableCell)`
    ${({ theme }) => loadFontAttributes(theme.typography.Large.bold)};
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh};
    height: 42px;
`;

const StyledTableHead = styled(TableHead)`
    background-color: ${({ theme }) => theme.palette.Basic.default_10};
    justify-content: space-between;
`;

const BodyTableCell = styled(StyledTableCell)<{ $width: number; $align?: string; $hidden?: boolean }>`
    background-color: ${({ theme }) => theme.palette.Background.secondary.default};
    ${({ theme }) => loadFontAttributes(theme.typography.Large.regular)};
    position: relative;
    height: 60px;
`;

const StyledDisabledTextField = styled(StyledTableCell)<{ $width: number; $align?: string; $hidden?: boolean }>`
    ${({ theme }) => loadFontAttributes(theme.typography.Large.regular)};
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh};
    border-bottom: none !important;
`;

export const SpecifyLabelsModal = ({
    isOpen,
    close,
    backButton,
    formControl,
    rows,
    saveChanges,
    isDirty,
    isValid,
}: {
    isOpen: boolean;
    close: () => void;
    backButton: () => void;
    formControl: Control;
    rows: ObjectLabelRow[];
    saveChanges: () => void;
    isDirty: boolean;
    isValid: boolean;
}) => {
    const columns = SPECIFY_LABELS_MODAL_COLUMNS;
    const [savingChanges, setSavingChanges] = React.useState(false);

    const renderColumnHeader = (column: Column) => {
        return (
            <HeaderTableCell $width={column.width} key={`col-${column.name}`} $align="center">
                {column.label}
            </HeaderTableCell>
        );
    };

    const renderHeader = () => {
        return <TableRow key="header-row">{columns.map((column) => renderColumnHeader(column))}</TableRow>;
    };

    const renderItem = (row: ObjectLabelRow, column: Column) => {
        const name = `${row.uuid}${ROW_COLUMN_SEPARATOR}${column.name}`;

        return column.editable ? (
            <TextTypeField name={name} rules={{ required: true }} control={formControl} />
        ) : (
            <StyledDisabledTextField $align="center" $width={column.width}>
                {row[column.name]}
            </StyledDisabledTextField>
        );
    };

    const renderRows = (rows: ObjectLabelRow[]) => {
        return rows.map((row: ObjectLabelRow, rowIndex: number) => (
            <TableRow key={`row-${row.uuid}`}>
                {columns.map((column, colIndex: number) => (
                    <BodyTableCell $width={200} key={`${rowIndex}-${colIndex}`}>
                        {renderItem(row, column)}
                    </BodyTableCell>
                ))}
            </TableRow>
        ));
    };

    const buildContent = () => {
        return (
            <div>
                <StyledTableHead id="table-header" data-testid="table-header">
                    {renderHeader()}
                </StyledTableHead>
                <TableBody>{renderRows(rows)}</TableBody>
            </div>
        );
    };

    const handleSaveChanges = () => {
        setSavingChanges(true);
        saveChanges();
        setSavingChanges(false);
    };

    return (
        <Dialog
            open={isOpen}
            onClose={close}
            title="Retrieve objects from CRM"
            subtitle="Name selected objects"
            primaryButtonCaption="Confirm"
            secondaryButtonCaption="Back"
            onPrimaryButtonClick={handleSaveChanges}
            onSecondaryButtonClick={backButton}
            primaryButtonDisabled={!isDirty || !isValid || savingChanges}
            content={buildContent()}
        />
    );
};
