import {
    AccuracyType,
    FieldType,
    PickListOption,
} from '../../domain/IntegrationConfiguration/IntegrationConfiguration';

export const ROW_COLUMN_SEPARATOR = '//';

export type Column = {
    id?: string;
    name: string;
    label: string;
    type: ColumnType;
    width: number;
    visible: boolean;
    editable: boolean;
    needsResync: boolean;
};

export const enum ColumnType {
    NUMBER = 'number',
    TEXT = 'text',
    BOOLEAN = 'boolean',
    SORTING_ORDER = 'sorting_order',
    PICKLIST_LABELS_VALUES = 'pick_list_labels_values',
    PICKLIST = 'pick_list',
    ACTION = 'action',
    ORDER = 'order',
    LOOK_UP_FIELDS = 'look_up_fields',
}

export type Field = {
    uuid: string;
    name: string;
    label: string;
    type: FieldType;
};

export type Option = {
    label: string;
    value: any;
    uuid?: string;
    description?: string;
};

export const PicklistFieldOptions: Option[] = [
    { label: 'Text', value: 'text', uuid: 'text' },
    { label: 'Number', value: 'numeric', uuid: 'numeric' },
    { label: 'Picklist', value: 'picklist', uuid: 'picklist' },
    { label: 'Address', value: 'address', uuid: 'address' },
    { label: 'Date/Time', value: 'datetime', uuid: 'datetime' },
    { label: 'Look up', value: 'lookup', uuid: 'lookup' },
];

export const DisplayTypeOptions: Option[] = [
    { label: 'TITLE', value: 'AS_TITLE' },
    { label: 'SUBTITLE', value: 'AS_SUBTITLE' },
    { label: 'DETAIL', value: 'AS_DETAIL' },
];

export const AddressAccuracyTypeOptions: Option[] = [
    {
        label: 'ROOFTOP',
        description: 'An accurate location to the street address precision',
        value: 'ROOFTOP',
        uuid: 'ROOFTOP',
    },
    {
        label: 'RANGE_INTERPOLATED',
        description: 'An approximation, usually on a road',
        value: 'RANGE_INTERPOLATED',
        uuid: 'RANGE_INTERPOLATED',
    },
    {
        label: 'GEOMETRIC_CENTER ',
        description: 'The center of a street or region',
        value: 'GEOMETRIC_CENTER',
        uuid: 'GEOMETRIC_CENTER',
    },
    {
        label: 'APPROXIMATE',
        description: 'An approximation',
        value: 'APPROXIMATE',
        uuid: 'APPROXIMATE',
    },
];

export const PicklistOptions: { [key: string]: any[] } = {
    displayType: DisplayTypeOptions,
    type: PicklistFieldOptions,
};

export const enum SortingOrderType {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
    UNDEFINED = '',
}

export type Row = {
    id?: string;
    uuid: string;
    displayOrder: number;
    label: string;
    name: string;
    type: string;
    isMandatory: boolean;
    sortingOrder: SortingOrderType;
    isMain: boolean;
    isSearchable: boolean;
    isColorizable: boolean | null;
    isFilterable: boolean | null;
    isTextArea: boolean | null;
    isViewOnly: boolean;
    displayType: string;
    pickList: PickListOption[] | null;
    decimals: number | null;
    relatedObjectTypes: string[] | null;
    addressAccuracyTypes: AccuracyType[] | null;
};
