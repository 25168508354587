import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../../theme';

export enum ScreenSize {
    EXTRA_SMALL = 1,
    SMALL,
    MEDIUM,
    LARGE,
}

export const useScreenSize = () => {
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));
    const isSmall = useMediaQuery(theme.breakpoints.up('sm'));

    const screenSize = isLarge
        ? ScreenSize.LARGE
        : isMedium
        ? ScreenSize.MEDIUM
        : isSmall
        ? ScreenSize.SMALL
        : ScreenSize.EXTRA_SMALL;

    return { screenSize };
};
