import { useEffect, useState } from 'react';
import { ScreenSize, useScreenSize } from '../../hooks/useScreenSize';

const usePageLayoutWithMenuController = () => {
    const { screenSize } = useScreenSize();
    const shouldHideMenu = screenSize === ScreenSize.EXTRA_SMALL || screenSize === ScreenSize.SMALL;
    const [open, setOpen] = useState(!shouldHideMenu);

    useEffect(() => {
        setOpen(!shouldHideMenu);
    }, [shouldHideMenu]);

    const handleDrawerOpen = () => setOpen(true);

    const handleDrawerClose = () => setOpen(false);

    return {
        open,
        handleDrawerOpen,
        handleDrawerClose,
    };
};

export { usePageLayoutWithMenuController };

export type usePageLayoutWithMenuControllerType = typeof usePageLayoutWithMenuController;
