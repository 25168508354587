import { Box, Grid, Stack } from '@mui/material';
import { loadFontAttributes } from 'design-system';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

const StyledGrid = styled(Grid)`
    background-color: ${({ theme }) => theme.palette.Background.bars.Color} !important;
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh} !important;
    padding: 10px 30px;
`;

const BreadcrumbsContainer = styled(Stack)`
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
`;

const PathElement = styled(Typography)`
    ${({ theme }) => loadFontAttributes(theme.typography.Small.medium)};
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh} !important;
    cursor: pointer;
    white-space: nowrap;
`;

const PathCurrent = styled(Typography)`
    ${({ theme }) => loadFontAttributes(theme.typography.Small.bold)};
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh} !important;
`;

const ToolsContainer = styled(Box)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const StyledTool = styled(Box)`
    height: 24px;
    width: 24px;
    cursor: pointer;
`;

const Title = styled(Typography)`
    text-align: center;
    ${({ theme }) => loadFontAttributes(theme.typography.XX_Large.light)};
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh} !important;
`;

const Subtitle = styled(Typography)`
    ${({ theme }) => loadFontAttributes(theme.typography.Small.medium)};
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh} !important;
    text-align: center;
`;

export type Breadcrumbs = {
    path: {
        name: string;
        onClick: () => void;
    }[];
};

export type Tool = JSX.Element;

export const HeaderWithBreadcrumbs = ({
    title,
    subtitle,
    breadcrumbs,
    tools,
}: {
    title: string;
    subtitle: string;
    breadcrumbs: Breadcrumbs;
    tools: Tool[];
}) => {
    const renderBreadCrumbs = () => {
        return (
            <BreadcrumbsContainer direction={'row'} gap={0.5}>
                {breadcrumbs.path.map((path, index) => {
                    if (index === breadcrumbs.path.length - 1) {
                        return <PathCurrent key={index}>{path.name}</PathCurrent>;
                    } else {
                        return (
                            <PathElement key={index} onClick={path.onClick}>
                                {path.name} /
                            </PathElement>
                        );
                    }
                })}
            </BreadcrumbsContainer>
        );
    };

    const renderTools = () => {
        return (
            <ToolsContainer>
                {tools.map((tool, index) => (
                    <StyledTool key={index}>{tool}</StyledTool>
                ))}
            </ToolsContainer>
        );
    };

    return (
        <StyledGrid container rowSpacing={1}>
            <Grid item xs={9}>
                {renderBreadCrumbs()}
            </Grid>
            <Grid item xs={3}>
                {renderTools()}
            </Grid>
            <Grid item xs={12}>
                <Title>{title}</Title>
            </Grid>
            <Grid item xs={12}>
                <Subtitle>{subtitle}</Subtitle>
            </Grid>
        </StyledGrid>
    );
};
