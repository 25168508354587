import styled from 'styled-components';
import { GridCellParams, GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import MultiUserSelector from './MultiUserSelector';
import { useCompanyUsersMainContentControllerType } from './hooks/useCompanyUsersMainContentController';
import { useMultiUserSelectorControllerType } from './hooks/useMultiUserSelectorController';
import { UserState } from '../../domain/Users/User';
import { ActiveCompanyIcon, InactiveCompanyIcon } from '../../common/components/ActiveStatusComponent';
import Button, { BUTTON_LEVELS, ButtonVariants } from '../../common/components/DS/Button';
import { StyledDataGrid } from '../../common/components/StyledDataGrid';
import { LoadingCircle } from '../../common/components/LoadingCircle';

const columns: GridColDef[] = [
    {
        field: 'username',
        flex: 3,
        headerName: 'Username',
        headerAlign: 'left',
        align: 'left',
        minWidth: 150,
    },
    {
        field: 'firstName',
        flex: 3,
        headerName: 'Name',
        headerAlign: 'left',
        align: 'left',
        minWidth: 120,
    },
    {
        field: 'lastName',
        flex: 3,
        headerName: 'Surname',
        headerAlign: 'left',
        align: 'left',
        minWidth: 120,
    },
    {
        field: 'id',
        flex: 2,
        headerName: 'User ID',
        headerAlign: 'left',
        align: 'left',
        minWidth: 100,
    },
    {
        field: 'email',
        flex: 5,
        headerName: 'Email',
        headerAlign: 'left',
        align: 'left',
        minWidth: 250,
    },
    {
        field: 'dateJoined',
        flex: 3,
        headerName: 'Date joined',
        headerAlign: 'left',
        align: 'left',
        minWidth: 150,
    },
    {
        field: 'state',
        flex: 1,
        headerName: 'Active',
        headerAlign: 'left',
        align: 'left',
        renderCell: (params: GridCellParams) =>
            params.value === UserState.ACTIVE ? <ActiveCompanyIcon /> : <InactiveCompanyIcon />,
        minWidth: 70,
    },
];

const StyledBoxDataGridContainer = styled(Box)`
    height: 100%;
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
`;

const ControlsContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 30px;
    height: 60px;
    gap: 10px;
`;

const CompanyUsersMainContent = ({
    useCompanyUsersMainContentController,
    useMultiUsersSelectorController,
}: {
    useCompanyUsersMainContentController: useCompanyUsersMainContentControllerType;
    useMultiUsersSelectorController: useMultiUserSelectorControllerType;
}) => {
    const { showAddUsersModal, setShowAddUsersModal, onConfirm, users, connectToCRM, isLoadingUsers } =
        useCompanyUsersMainContentController();

    const renderConnectToCRMButton = () => (
        <Button variant={ButtonVariants.CONTAINED} onClick={connectToCRM} severity={BUTTON_LEVELS.INFO}>
            <div data-testid="connect-to-crm-caption">Connect to CRM</div>
        </Button>
    );

    const renderAddUsersButton = () => (
        <Button
            variant={ButtonVariants.CONTAINED}
            onClick={() => setShowAddUsersModal(true)}
            severity={BUTTON_LEVELS.INFO}
        >
            <div data-testid="add-users-button-caption">Add users</div>
        </Button>
    );

    const renderAddUsersModal = () => (
        <MultiUserSelector
            visible={showAddUsersModal}
            useController={useMultiUsersSelectorController}
            onConfirm={onConfirm}
            onClose={() => setShowAddUsersModal(false)}
            selectLabel="Select"
            selectedLabel="Selected to be retrieved"
            description="User full name / Email"
        />
    );
    return isLoadingUsers ? (
        <LoadingCircle />
    ) : (
        <>
            {showAddUsersModal && renderAddUsersModal()}
            <StyledBoxDataGridContainer>
                <ControlsContainer>
                    {renderConnectToCRMButton()}
                    {renderAddUsersButton()}
                </ControlsContainer>

                {/* columnBuffer is needed to avoid virtualization of the page which makes the test not render all the columns. Check https://stackoverflow.com/questions/65669707/problem-testing-material-ui-datagrid-with-react-testing-library  */}
                <StyledDataGrid
                    rows={users}
                    columns={columns}
                    columnBuffer={8}
                    disableRowSelectionOnClick
                    slots={{ toolbar: GridToolbarQuickFilter }}
                    localeText={{ toolbarQuickFilterPlaceholder: 'Search User by Name, ID or Email' }}
                    slotProps={{
                        toolbar: {
                            sx: {
                                width: '400px',
                            },
                        },
                    }}
                />
            </StyledBoxDataGridContainer>
        </>
    );
};

export default CompanyUsersMainContent;
