import { createTheme } from '@mui/material/styles';

// Colors cannot be modified here. They are defined in the design system.
const CommonTheme = createTheme({
    palette: {
        Basic: {
            _100: '#f0f0f0',
            _200: '#fafafa',
            dark: '#585858',
            darker: '#373333',
            default: '#979797',
            default_10: '#f0f0f1',
            default_20: '#d5d5d5',
            light: '#cecece',
            light_70: '#dbdbdc',
            lighter: '#f5f5f5',
            mainMenu: '#302d2d',
            mainMenu_O: '#274060',
            transparent: '#ffffff',
            white: '#ffffff',
        },
        Logo: { main: '#c23615' },
        O_Accent: { default: '#f3b61f', default_20: '#f8ebce', default_8: '#f8f4e8' },
        O_Primary: { dark: '#274060', darker: '#1b2845', default: '#4a90e2', default_20: '#d6e4f5', light: '#2f5b8f' },
        State: {
            alert: '#de3d17',
            alert_20: '#de3d1733',
            alert_5: '#de3d170d',
            destructive: '#de3d17',
            info: '#2f5b8f',
            info_20: '#2f5b8f33',
            info_5: '#2f5b8f0d',
            success: '#0db87f',
            success_20: '#0db87f33',
            success_5: '#0db87f0d',
            warning: '#f1d44a',
            warningDark: '#82711c',
            warning_20: '#f1d44a33',
        },
        W_Primary: {
            dark: '#2f5b8f',
            default: '#4a90e2',
            default_10: '#e8eff8',
            default_40: '#b3cff0',
            h1: '#53a2ff',
            h2: '#d6e4f5',
        },
        W_Secondary: { dark: '#7d2a16', default: '#c23615', hover: '#8a3520' },
    },
});

const OtterTheme = createTheme({
    globalSizes: {
        menuWidth: '200px',
        condensedMenuWidth: '65px',
    },
    palette: {
        Background: {
            bars: { footers: { toolbar: '#1df89c' }, headers: { secondaryNav: CommonTheme.palette.O_Primary?.dark } },
            button: {
                control: {
                    notSelected: CommonTheme.palette.Basic?.white,
                    selected: CommonTheme.palette.O_Accent?.default_8,
                },
                mainMenu: {
                    dataNotSelected: '#22df4b',
                    dataSelected: '#45ec6a',
                    disabled: CommonTheme.palette.Basic?.transparent,
                    enabled: CommonTheme.palette.O_Primary?.darker,
                    hover: CommonTheme.palette.Basic?._100,
                    objectSelected: '#74f953',
                    selected: CommonTheme.palette.Basic?.mainMenu_O,
                },
                primary: {
                    active: CommonTheme.palette.Basic?.default,
                    destructive: CommonTheme.palette.State?.alert,
                    directFeedback: '#53f75a',
                    disabled: CommonTheme.palette.Basic?.light_70,
                    enabled: CommonTheme.palette.O_Primary?.default,
                    hover: CommonTheme.palette.O_Primary?.default_20,
                },
                secondary: {
                    active: '#5add67',
                    directFeedback: '#53f75a',
                    disabled: CommonTheme.palette.Basic?.light_70,
                    enabled: CommonTheme.palette.Basic?.transparent,
                    hover: CommonTheme.palette.O_Accent?.default_20,
                },
                tertiary: {
                    active: CommonTheme.palette.Basic?.transparent,
                    directFeedback: CommonTheme.palette.Basic?.transparent,
                    disabled: '#24e520',
                    enabled: CommonTheme.palette.Basic?.transparent,
                    hover: '#1ed430',
                },
            },
            fields: {
                picklist: { default: '#6aff35' },
                searchBar: { default: CommonTheme.palette.Basic?.white },
                suggestions: { default: '#6aff35' },
                textField: {
                    default: CommonTheme.palette.Basic?._200,
                    error: CommonTheme.palette.Basic?.white,
                    filled: CommonTheme.palette.Basic?.white,
                    focus: CommonTheme.palette.O_Primary?.default_20,
                },
            },
            listItems: {
                active: '#ffffff',
                default: '#1b2845',
                hover: '#ffffff',
                'hover2 (remove when fixing)': '#ffffff',
                selected: '#ffffff',
                related: { collapsed: '#37ed33', expanded: '#40f930' },
            },
            menu: { default: CommonTheme.palette.O_Primary?.darker },
            'primary (white)': { default: CommonTheme.palette.Basic?.white },
            'secondary (grisecillo)': { default: CommonTheme.palette.Basic?.lighter },
            state: {
                alert: CommonTheme.palette.State?.alert,
                alert2: CommonTheme.palette.State?.alert_5,
                alert3: CommonTheme.palette.State?.alert_20,
                info: CommonTheme.palette.State?.info,
                info2: CommonTheme.palette.State?.info_5,
                info3: CommonTheme.palette.State?.info_20,
                success: CommonTheme.palette.State?.success,
                success2: CommonTheme.palette.State?.success_5,
                success3: CommonTheme.palette.State?.success_20,
                warning: CommonTheme.palette.State?.warning,
                warning2: CommonTheme.palette.State?.warning_20,
            },
            summary: { body: '#4eff95', header: '#54ffb7', 'multiple-summary': '#39ffa0' },
            table: {
                content: {
                    default: CommonTheme.palette.Basic?.white,
                    hover: CommonTheme.palette.O_Primary?.default_20,
                    selected: CommonTheme.palette.O_Accent?.default_20,
                },
                header: { default: CommonTheme.palette.Basic?._100 },
            },
            tooltip: { default: CommonTheme.palette.O_Primary?.light },
        },
        Border: {
            button: {
                active: '#35ed69',
                directFeedback: '#3af199',
                disabled: CommonTheme.palette.Basic?._100,
                enabled: CommonTheme.palette.O_Accent?.default,
                hover: CommonTheme.palette.O_Accent?.default,
            },
            fields: {
                searchBar: { default: CommonTheme.palette.O_Accent?.default },
                textField: {
                    default: CommonTheme.palette.Basic?.default,
                    error: CommonTheme.palette.State?.alert,
                    filled: CommonTheme.palette.O_Primary?.default_20,
                    focus: CommonTheme.palette.O_Primary?.light,
                },
            },
            listItem: { default: '#5deb5a' },
        },
        Brand: {
            accent: { default: CommonTheme.palette.O_Accent?.default },
            primary: { default: CommonTheme.palette.O_Primary?.default },
            secondary: { default: CommonTheme.palette.Logo?.main },
        },
        Text: {
            onButton: {
                primary: {
                    active: CommonTheme.palette.Basic?._100,
                    destructive: '#54e039',
                    'direct-feedback': '#54e039',
                    disabled: CommonTheme.palette.Basic?._100,
                    enabled: CommonTheme.palette.Basic?._100,
                    hover: CommonTheme.palette.Basic?.mainMenu_O,
                },
                secondary: {
                    active: '#54e039',
                    'direct-feedback': '#54e039',
                    disabled: CommonTheme.palette.Basic?._100,
                    enabled: CommonTheme.palette.Basic?.dark,
                    hover: CommonTheme.palette.Basic?.dark,
                },
                tertiary: {
                    active: CommonTheme.palette.O_Primary?.darker,
                    'direct-feedback': CommonTheme.palette.W_Primary?.default_40,
                    disabled: '#54e039',
                    enabled: CommonTheme.palette.O_Primary?.default,
                    hover: CommonTheme.palette.O_Primary?.darker,
                },
            },
            onControl: {
                notSelected: CommonTheme.palette.O_Primary?.darker,
                selected: CommonTheme.palette.O_Primary?.darker,
            },
            onMenu: {
                disabled: CommonTheme.palette.Basic?.white,
                enabled: CommonTheme.palette.Basic?.white,
                hover: CommonTheme.palette.O_Primary?.darker,
                secondarySelected: '#69cc67',
                selected: CommonTheme.palette.Basic?.white,
            },
            onSurface: {
                accent: CommonTheme.palette.O_Accent?.default,
                high: CommonTheme.palette.O_Primary?.dark,
                link: '#54e039',
                low: CommonTheme.palette.Basic?.light,
                medium: CommonTheme.palette.Basic?.default,
                xHigh: CommonTheme.palette.O_Primary?.darker,
                xLow: CommonTheme.palette.Basic?.white,
            },
            onTools: { default: '#54e039', selected: '#54e039' },
            state: {
                alert: CommonTheme.palette.State?.alert,
                alert2: CommonTheme.palette.State?.alert_5,
                alert3: CommonTheme.palette.State?.alert_20,
                info: CommonTheme.palette.State?.info,
                info2: CommonTheme.palette.State?.info_5,
                info3: CommonTheme.palette.State?.info_20,
                success: CommonTheme.palette.State?.success,
                success2: CommonTheme.palette.State?.success_5,
                success3: CommonTheme.palette.State?.success_20,
                warning: CommonTheme.palette.State?.warning,
                warningDark: CommonTheme.palette.State?.warningDark,
            },
        },
    },
    typography: {
        Large: {
            bold: {
                center: {
                    'font-family': "'Noto Sans'",
                    'font-size': '16px',
                    'letter-spacing': '0px',
                    'line-height': '100%',
                    'font-weight': '700',
                    'text-align': 'center',
                },
                left: {
                    'font-family': "'Noto Sans'",
                    'font-size': '16px',
                    'letter-spacing': '0px',
                    'line-height': '100%',
                    'font-weight': '700',
                    'text-align': 'left',
                },
            },
            regular: {
                center: {
                    'font-family': "'Noto Sans'",
                    'font-size': '16px',
                    'letter-spacing': '0px',
                    'line-height': '100%',
                    'font-weight': '400',
                    'text-align': 'center',
                },
                left: {
                    'font-family': "'Noto Sans'",
                    'font-size': '16px',
                    'letter-spacing': '0px',
                    'line-height': '100%',
                    'font-weight': '400',
                    'text-align': 'left',
                },
            },
        },
        Medium: {
            bold: {
                left: {
                    'font-family': "'Noto Sans'",
                    'font-size': '14px',
                    'letter-spacing': '0px',
                    'line-height': '100%',
                    'font-weight': '700',
                    'text-align': 'left',
                },
            },
            italic: {
                left: {
                    'font-family': "'Noto Sans'",
                    'font-size': '14px',
                    'letter-spacing': '0px',
                    'line-height': '100%',
                    'font-weight': '400',
                    'font-style': 'italic',
                    'text-align': 'left',
                },
            },
            regular: {
                left: {
                    'font-family': "'Noto Sans'",
                    'font-size': '14px',
                    'letter-spacing': '0px',
                    'line-height': '100%',
                    'font-weight': '400',
                    'text-align': 'left',
                },
            },
        },
        Small: {
            bold: {
                left: {
                    'font-family': "'Noto Sans'",
                    'font-size': '12px',
                    'letter-spacing': '0px',
                    'line-height': '100%',
                    'font-weight': '700',
                    'text-align': 'left',
                },
            },
            italic: {
                left: {
                    'font-family': "'Noto Sans'",
                    'font-size': '12px',
                    'letter-spacing': '0px',
                    'line-height': '100%',
                    'font-weight': '400',
                    'font-style': 'italic',
                    'text-align': 'left',
                },
            },
            medium: {
                left: {
                    'font-family': "'Noto Sans'",
                    'font-size': '12px',
                    'letter-spacing': '0px',
                    'line-height': '100%',
                    'font-weight': '500',
                    'text-align': 'left',
                },
            },
            regular: {
                left: {
                    'font-family': "'Noto Sans'",
                    'font-size': '12px',
                    'letter-spacing': '0px',
                    'line-height': '100%',
                    'font-weight': '400',
                    'text-align': 'left',
                },
            },
        },
        'X-Small': {
            regular: {
                left: {
                    'font-family': "'Noto Sans'",
                    'font-size': '10px',
                    'letter-spacing': '0px',
                    'line-height': 'normal',
                    'font-weight': '400',
                    'text-align': 'left',
                },
            },
        },
        'X-Large': {
            semiBold: {
                left: {
                    'font-family': "'Noto Sans'",
                    'font-size': '20px',
                    'letter-spacing': '0px',
                    'line-height': '100%',
                    'font-weight': '600',
                    'text-align': 'left',
                },
            },
        },
        'XX-Large': {
            light: {
                center: {
                    'font-family': "'Noto Sans'",
                    'font-size': '30px',
                    'letter-spacing': '0px',
                    'line-height': '100%',
                    'font-weight': '300',
                    'text-align': 'center',
                },
            },
            medium: {
                left: {
                    'font-family': "'Noto Sans'",
                    'font-size': '30px',
                    'letter-spacing': '0px',
                    'line-height': '100%',
                    'font-weight': '500',
                    'text-align': 'left',
                },
            },
        },
        Logo: {
            'font-family': "'Martel Sans'",
            'font-size': '42px',
            'letter-spacing': '0px',
            'line-height': '40px',
            'font-weight': '275',
            'text-align': 'center',
        },
    },
});

declare module '@mui/material/styles' {
    interface ThemeOptions {
        globalSizes?: {
            menuWidth: string;
            condensedMenuWidth: string;
        };
    }

    interface Palette {
        W_Primary: {
            default: string;
            dark: string;
            h1: string;
            default_40: string;
            h2: string;
            default_10: string;
        };
        State: {
            destructive: string;
            info: string;
            warning: string;
            alert: string;
            alert_5: string;
            info_5: string;
            warning_20: string;
            success_5: string;
            success: string;
            alert_20: string;
            info_20: string;
            success_20: string;
            warningDark: string;
        };
        Basic: {
            _100: string;
            _200: string;
            default: string;
            lighter: string;
            dark: string;
            light: string;
            darker: string;
            default_10: string;
            default_20: string;
            mainMenu: string;
            white: string;
            light_70: string;
            transparent: string;
            mainMenu_O: string;
        };
        W_Secondary: {
            default: string;
            dark: string;
            hover: string;
        };
        Button: {
            default: string;
            wolverine: string;
            otter: string;
        };
        O_Accent: {
            default_8: string;
            default: string;
            default_20: string;
        };
        O_Primary: {
            default: string;
            default_20: string;
            darker: string;
            light: string;
            dark: string;
        };
        Logo: {
            main: string;
        };

        Background: {
            button: {
                primary: {
                    enabled: string;
                    hover: string;
                    active: string;
                    directFeedback: string;
                    disabled: string;
                    destructive: string;
                };
                control: {
                    selected: string;
                    notSelected: string;
                };
                secondary: {
                    active: string;
                    directFeedback: string;
                    enabled: string;
                    hover: string;
                    disabled: string;
                };
                tertiary: {
                    enabled: string;
                    active: string;
                    disabled: string;
                    directFeedback: string;
                };
                mainMenu: {
                    dataNotSelected: string;
                    dataSelected: string;
                    selected: string;
                    hover: string;
                    objectSelected: string;
                    disabled: string;
                    enabled: string;
                };
            };
            'primary (white)': {
                default: string;
            };
            menu: {
                default: string;
            };
            'secondary (grisecillo)': {
                default: string;
            };
            bars: {
                headers: {
                    secondaryNav: string;
                };
                footers: {
                    toolbar: string;
                };
            };
            state: {
                info: string;
                alert: string;
                warning: string;
                info2: string;
                alert2: string;
                warning2: string;
                success2: string;
                success: string;
                alert3: string;
                info3: string;
                success3: string;
            };
            fields: {
                picklist: {
                    default: string;
                };
                textField: {
                    default: string;
                    focus: string;
                    filled: string;
                    error: string;
                };
                searchBar: {
                    default: string;
                };
                suggestions: {
                    default: string;
                };
            };
            tooltip: {
                default: string;
            };
            table: {
                header: {
                    default: string;
                };
                content: {
                    default: string;
                    selected: string;
                    hover: string;
                };
            };
            listItems: {
                active: string;
                default: string;
                hover: string;
                'hover2 (remove when fixing)': string;
                selected: string;
                related: {
                    collapsed: string;
                    expanded: string;
                };
            };
            summary: {
                body: string;
                header: string;
                'multiple-summary': string;
            };
        };
        Brand: {
            primary: {
                default: string;
            };
            secondary: {
                default: string;
            };
            accent: {
                default: string;
            };
        };
        Border: {
            fields: {
                textField: {
                    default: string;
                    error: string;
                    filled: string;
                    focus: string;
                };
                searchBar: {
                    default: string;
                };
            };
            button: {
                active?: string;
                directFeedback?: string;
                enabled: string;
                hover: string;
                disabled: string;
            };
            listItem: {
                default: string;
            };
        };
        Text: {
            onControl: {
                selected: string;
                notSelected: string;
            };
            onButton: {
                primary: {
                    enabled: string;
                    hover: string;
                    active: string;
                    disabled: string;
                    destructive: string;
                };
                secondary: {
                    active: string;
                    enabled: string;
                    hover: string;
                    disabled: string;
                };
                tertiary: {
                    enabled: string;
                    hover: string;
                    active: string;
                    disabled: string;
                    'direct-feedback': string;
                };
                onTools: {
                    default: string;
                    selected: string;
                };
            };
            onMenu: {
                selected: string;
                enabled: string;
                hover: string;
                disabled: string;
                secondarySelected: string;
            };
            onSurface: {
                xHigh: string;
                high: string;
                medium: string;
                low: string;
                xLow: string;
                accent: string;
                link: string;
            };
            state: {
                info: string;
                info2: string;
                alert: string;
                alert2: string;
                warning: string;
                warningDark: string;
                success: string;
                success2: string;
                alert3: string;
                info3: string;
                success3: string;
            };
        };
    }

    interface PaletteOptions {
        W_Primary?: {
            default?: string;
            dark?: string;
            h1?: string;
            default_40: string;
            h2: string;
            default_10: string;
        };
        State?: {
            destructive: string;
            info: string;
            warning: string;
            alert: string;
            alert_5: string;
            info_5: string;
            warning_20: string;
            success_5: string;
            success: string;
            alert_20: string;
            info_20: string;
            success_20: string;
            warningDark: string;
        };
        Basic?: {
            _100: string;
            _200: string;
            default: string;
            lighter: string;
            dark: string;
            light: string;
            darker: string;
            default_10: string;
            default_20: string;
            mainMenu: string;
            white: string;
            light_70: string;
            transparent: string;
            mainMenu_O: string;
        };
        W_Secondary?: {
            default?: string;
            dark?: string;
            hover?: string;
        };
        Button?: {
            default?: string;
            wolverine?: string;
            otter?: string;
        };
        O_Accent?: {
            default_8: string;
            default: string;
            default_20: string;
        };
        O_Primary?: {
            default: string;
            default_20: string;
            darker: string;
            light: string;
            dark: string;
        };
        Logo?: {
            main: string;
        };

        Background?: {
            button?: {
                control?: {
                    selected?: string;
                    notSelected?: string;
                };
                mainMenu?: {
                    dataNotSelected?: string;
                    dataSelected?: string;
                    selected?: string;
                    hover?: string;
                    objectSelected?: string;
                    disabled?: string;
                    enabled?: string;
                };
                primary?: {
                    enabled?: string;
                    hover?: string;
                    active?: string;
                    disabled?: string;
                    destructive?: string;
                    directFeedback?: string;
                };
                secondary?: {
                    active?: string;
                    enabled?: string;
                    hover?: string;
                    disabled?: string;
                    directFeedback?: string;
                };
                tertiary?: {
                    enabled?: string;
                    active?: string;
                    disabled?: string;
                    directFeedback?: string;
                    hover?: string;
                };
            };
            'primary (white)'?: {
                default?: string;
            };
            menu?: {
                default?: string;
            };
            'secondary (grisecillo)'?: {
                default?: string;
            };
            bars?: {
                headers?: {
                    secondaryNav: string;
                };
                footers?: {
                    toolbar: string;
                };
            };
            state?: {
                info?: string;
                alert?: string;
                warning?: string;
                info2?: string;
                alert2?: string;
                warning2?: string;
                success2?: string;
                success?: string;
                alert3?: string;
                info3?: string;
                success3?: string;
            };
            fields?: {
                textField?: {
                    default?: string;
                    focus?: string;
                    filled?: string;
                    error?: string;
                };
                searchBar: {
                    default?: string;
                };
                picklist: {
                    default?: string;
                };
                suggestions: {
                    default?: string;
                };
            };
            tooltip?: {
                default?: string;
            };
            table?: {
                header?: {
                    default?: string;
                };
                content?: {
                    default?: string;
                    selected?: string;
                    hover?: string;
                };
            };
            listItems: {
                active?: string;
                default?: string;
                hover?: string;
                'hover2 (remove when fixing)'?: string;
                selected?: string;
                related?: {
                    collapsed?: string;
                    expanded?: string;
                };
            };
            summary: {
                body?: string;
                header?: string;
                'multiple-summary'?: string;
            };
        };
        Brand?: {
            primary?: {
                default?: string;
            };
            secondary?: {
                default?: string;
            };
            accent?: {
                default?: string;
            };
        };
        Border?: {
            fields?: {
                textField?: {
                    default?: string;
                    error?: string;
                    filled?: string;
                    focus?: string;
                };
                searchBar?: {
                    default?: string;
                };
            };
            button?: {
                active?: string;
                directFeedback?: string;
                enabled?: string;
                hover?: string;
                disabled?: string;
            };
            listItem: {
                default?: string;
            };
        };
        Text?: {
            onControl?: {
                selected?: string;
                notSelected?: string;
            };
            onButton?: {
                primary?: {
                    enabled?: string;
                    hover?: string;
                    active?: string;
                    disabled?: string;
                    destructive?: string;
                    'direct-feedback'?: string;
                };
                secondary?: {
                    active?: string;
                    enabled?: string;
                    hover?: string;
                    disabled?: string;
                    'direct-feedback'?: string;
                };
                tertiary?: {
                    enabled?: string;
                    hover?: string;
                    active?: string;
                    disabled?: string;
                    'direct-feedback'?: string;
                };
            };
            onMenu?: {
                selected?: string;
                enabled?: string;
                hover?: string;
                disabled?: string;
                secondarySelected?: string;
            };
            onSurface?: {
                xHigh?: string;
                high?: string;
                medium?: string;
                low?: string;
                xLow?: string;
                accent?: string;
                link?: string;
            };
            state?: {
                info?: string;
                info2?: string;
                alert?: string;
                alert2?: string;
                warning?: string;
                warningDark?: string;
                success?: string;
                success2?: string;
                alert3?: string;
                info3?: string;
                success3?: string;
            };
            onTools: { default?: string; selected?: string };
        };
    }
}

declare module '@mui/material/styles/createTypography' {
    interface TypographyOptions {
        Large: {
            bold: {
                center: TypographyStyleOptions;
                left: TypographyStyleOptions;
            };
            regular: {
                center: TypographyStyleOptions;
                left: TypographyStyleOptions;
            };
        };
        Medium: {
            bold: {
                left: TypographyStyleOptions;
            };
            italic: {
                left: TypographyStyleOptions;
            };
            regular: {
                left: TypographyStyleOptions;
            };
        };
        Small: {
            bold: {
                left: TypographyStyleOptions;
            };
            italic: {
                left: TypographyStyleOptions;
            };
            medium: {
                left: TypographyStyleOptions;
            };
            regular: {
                left: TypographyStyleOptions;
            };
        };
        'X-Small': {
            regular: {
                left: TypographyStyleOptions;
            };
        };
        'X-Large': {
            semiBold: {
                left: TypographyStyleOptions;
            };
        };
        'XX-Large': {
            light: {
                center: TypographyStyleOptions;
            };
            medium: {
                left: TypographyStyleOptions;
            };
        };
        Logo: TypographyStyleOptions;
    }
}

export default OtterTheme;
