import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { Dialog, GenericInputField, LABEL_PLACEMENT, SWITCH_SEVERITY, ToggleSwitch } from 'design-system';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

type ModalSelectorObject = {
    id: string;
    primaryText: string;
    secondaryText: string;
    isExcluded: boolean;
};

export type { ModalSelectorObject };

export interface UniversalModalSelectorProps {
    visible: boolean;
    onConfirm: () => void;
    onClose: () => void;
    objects: ModalSelectorObject[];
    selectedObjectIds: string[];
    loading: boolean;
    handleOnObjectClick: (id: string) => void;
    modalTitle: string;
    modalSubtitle?: string;
    objectDescription?: string;
    uncheckedLabel: string;
    checkedLabel: string;
    primaryButtonCaption: string;
    secondaryButtonCaption?: string;
    secondaryButtonAction?: () => void;
}

const StyledList = styled(List)`
    &.MuiList-root {
        padding: 0;
    }
`;

const StyledListItem = styled(ListItem)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
`;

const StyledListHeader = styled(ListItem)`
    background-color: ${({ theme }) => theme.palette.Basic.default_10};
`;

const StyledGenericInputField = styled(GenericInputField)`
    background-color: white;
    &.MuiFormControl-root {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 20px;
        .MuiInputBase-root {
            width: 100%;
        }
    }
    & .MuiFormLabel-root {
        position: absolute;
        left: 20px;
        top: 20%;
    }
`;

const StyledBox = styled(Box)`
    background-color: white;
`;

const ModalSelector = ({
    visible,
    onConfirm,
    onClose,
    objects,
    selectedObjectIds,
    loading,
    handleOnObjectClick,
    modalTitle,
    modalSubtitle,
    objectDescription,
    uncheckedLabel,
    checkedLabel,
    primaryButtonCaption,
    secondaryButtonCaption,
    secondaryButtonAction,
}: UniversalModalSelectorProps) => {
    const [toggleStates, setToggleStates] = useState<{ [key: string]: boolean }>({});
    const [selectedCount, setSelectedCount] = useState<number>(selectedObjectIds.length);
    const [searchValue, setSearchValue] = useState('');
    const [filteredObjects, setFilteredObjects] = useState(objects);

    useEffect(() => {
        const initialToggleStates = objects.reduce(
            (acc, object) => {
                acc[object.id] = selectedObjectIds.includes(object.id) || object.isExcluded;
                return acc;
            },
            {} as { [key: string]: boolean }
        );
        setToggleStates(initialToggleStates);
    }, [objects, selectedObjectIds]);

    useEffect(() => {
        if (searchValue.length > 0) {
            const lowerCaseSearchValue = searchValue.toLowerCase();
            const filtered = objects.filter(
                (object) =>
                    object.primaryText.toLowerCase().includes(lowerCaseSearchValue) ||
                    object.secondaryText.toLowerCase().includes(lowerCaseSearchValue)
            );
            setFilteredObjects(filtered);
        } else {
            setFilteredObjects(objects);
        }
    }, [searchValue, objects]);

    const primaryButtonDisabled = !filteredObjects.some((object) => !object.isExcluded);

    const handleToggleChange = (id: string, checked: boolean) => {
        setToggleStates((prevState) => ({
            ...prevState,
            [id]: checked,
        }));
        handleOnObjectClick(id);
        setSelectedCount((prevCount) => (checked ? prevCount + 1 : prevCount - 1));
    };

    const content = (
        <div>
            {objects.length ? (
                <StyledList>
                    <StyledBox>
                        <StyledGenericInputField
                            label="Search"
                            value={searchValue}
                            updateValue={setSearchValue}
                            placeholder="Search..."
                        />
                    </StyledBox>
                    {objectDescription && (
                        <StyledListHeader>
                            <ListItemText
                                primary={objectDescription}
                                primaryTypographyProps={{ style: { fontWeight: 'bold' } }}
                            />
                        </StyledListHeader>
                    )}
                    {filteredObjects.map((object) => (
                        <Fragment key={object.id}>
                            <StyledListItem>
                                <ListItemText primary={object.primaryText} secondary={object.secondaryText} />
                                <Box>
                                    <ToggleSwitch
                                        checked={toggleStates[object.id] || false}
                                        setChecked={(checked) => handleToggleChange(object.id, checked)}
                                        checkedLabel={
                                            object.isExcluded ? 'Already integrated with Badger' : checkedLabel
                                        }
                                        uncheckedLabel={uncheckedLabel}
                                        disabled={object.isExcluded}
                                        labelPlacement={LABEL_PLACEMENT.START}
                                        severity={SWITCH_SEVERITY.DEFAULT}
                                    />
                                </Box>
                            </StyledListItem>
                            <Divider variant="fullWidth" component="li" />
                        </Fragment>
                    ))}
                </StyledList>
            ) : (
                <Typography variant={'h6'} align={'center'}>
                    There is no content to display
                </Typography>
            )}
        </div>
    );

    return (
        <Dialog
            title={modalTitle}
            subtitle={modalSubtitle}
            content={content}
            primaryButtonCaption={primaryButtonCaption}
            onPrimaryButtonClick={() => {
                onConfirm();
            }}
            secondaryButtonCaption={
                secondaryButtonCaption && secondaryButtonAction ? secondaryButtonCaption : undefined
            }
            onSecondaryButtonClick={secondaryButtonAction && secondaryButtonCaption ? secondaryButtonAction : undefined}
            onClose={onClose}
            open={visible}
            loading={loading}
            primaryButtonDisabled={primaryButtonDisabled}
        />
    );
};

export default ModalSelector;
