import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from 'styled-components';
import { loadFontAttributes } from 'design-system';

const CustomizedAccordion = styled(Accordion)`
    &.MuiAccordion-root {
        padding-bottom: 0;
    }
    & .MuiTypography-root {
        ${({ theme }) => loadFontAttributes(theme.typography.X_Large.semibold)};
        color: ${({ theme }) => theme.palette.Text.onSurface.high};
        font-weight: 600;
        font-size: 20px;
    }
`;

export interface AccordionComponentProps {
    title: string;
    children: React.ReactNode;
    className?: string;
}

const AccordionComponent = (props: AccordionComponentProps) => {
    const { title, children, className } = props;
    return (
        <CustomizedAccordion className={className}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </CustomizedAccordion>
    );
};

export default AccordionComponent;
