import ObjectDefinitionTable from '../ObjectDefinitionsTable/ObjectDefinitionsTable';
import { styled } from 'styled-components';
import {
    Alignments,
    Button,
    BUTTON_SEVERITY_LEVELS,
    ButtonTypes,
    ChildrenContainer,
    ConfirmationDialog,
    loadFontAttributes,
    Popover,
    SEVERITY_LEVELS,
} from 'design-system';
import ObjectParameters from '../ObjectParameters/ObjectParameters';
import { useObjectParametersController } from '../ObjectParameters/objectParametersController';
import ColumnsVisibilityEditor from '../ObjectDefinitionsTable/ColumnsVisibilityEditor/ColumnsVisibilityEditor';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { useRef } from 'react';
import { useObjectDefinitionContentController } from './objectDefinitionContentController';
import AccordionComponent from '../AccordionComponent/AccordionComponent';
import CustomJSEditor from '../CustomJSEditor/CustomJSEditor';

const ActionBar = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 20px;
    gap: 20px;
    border-top: 2px solid ${({ theme }) => theme.palette.Basic.lighter};
`;
const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;

    ${ChildrenContainer} {
        width: 125px !important;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 85px);
    align-items: center;
    padding: 5px 15px 0 15px;
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
    overflow-y: auto;
    overflow-x: hidden;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
`;

const Title = styled.h2`
    margin: 0;
    padding: 20px;
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh};
    ${({ theme }) => loadFontAttributes(theme.typography.X_Large.semibold)};
`;

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: 300px;
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
`;

const ObjectParametersContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: fit-content;
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 45px);
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ConfirmationDialogContainer = styled.div`
    z-index: 9999 !important;
`;

const StyledVisibilityButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    min-width: 50px;
`;

const CollapsibleCustomJSWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: fit-content;
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
`;

const ObjectDefinitionContent = () => {
    const {
        columns,
        rows,
        control,
        getValues,
        isDirty,
        isValid,
        savingChanges,
        saveChanges,
        confirmationDialogOpen,
        closeConfirmationDialog,
        resetState,
        discardChangesFromRouteBlockerPopup,
        addField,
        addDefaultTemplate,
        deleteField,
        reorder,
        fields,
        handleInputChange,
        handleMultiPicklistChange,
        handleColumnsVisibilityChange,
        handleOnColumnsVisibilityEditorClick,
        scrollingContainerRef,
        fieldsParametersTitleRef,
        tableContainerRef,
        tableScrollOffset,
        showColumnsVisibilityEditor,
        buildLookUpOptions,
        mroOptions,
        defaultFieldsLoaded,
    } = useObjectDefinitionContentController();

    const showCustomJSEditor = false; // TODO: Implement custom JS editor

    const columnsVisibilityButtonRef = useRef<HTMLDivElement>(null);

    const renderColumnsVisibilityEditorButton = () => {
        return (
            <StyledVisibilityButtonWrapper ref={columnsVisibilityButtonRef}>
                <Button
                    tooltip="Manage columns visibility"
                    type={ButtonTypes.TEXT}
                    alignment={Alignments.ROW}
                    icon={<ViewColumnIcon />}
                    severity={BUTTON_SEVERITY_LEVELS.INFO}
                    onClick={(e) => {
                        handleOnColumnsVisibilityEditorClick(true);
                        e.preventDefault();
                    }}
                >
                    Manage columns
                </Button>
            </StyledVisibilityButtonWrapper>
        );
    };

    return (
        <MainContainer>
            <Container ref={scrollingContainerRef} data-testid="scrolling-container">
                <StyledForm style={{}}>
                    <TableContainer ref={tableContainerRef}>
                        <TitleContainer>
                            <Title ref={fieldsParametersTitleRef}>Fields parameters</Title>
                            {renderColumnsVisibilityEditorButton()}
                        </TitleContainer>
                        <ObjectDefinitionTable
                            columns={columns}
                            rows={rows}
                            addField={addField}
                            addDefaultTemplate={addDefaultTemplate}
                            deleteField={deleteField}
                            reorder={reorder}
                            formControl={control}
                            handleInputChange={handleInputChange}
                            handleMultiPicklistChange={handleMultiPicklistChange}
                            scrollOffset={tableScrollOffset}
                            handleColumnsVisibilityChange={handleColumnsVisibilityChange}
                            lookUpOptions={buildLookUpOptions()}
                            getValues={getValues}
                        />
                    </TableContainer>
                    {showCustomJSEditor && (
                        <CollapsibleCustomJSWrapper>
                            <AccordionComponent title="Custom JS">
                                <CustomJSEditor
                                    value={
                                        '// CRM to Badger\nif (fromVendor) {\n\n\n}\n// Badger to CRM\nelse {\n\n\n}\ndone(transformedObject);'
                                    }
                                    height="300px"
                                    width="100%"
                                    onChangeHandler={() => {}}
                                />
                            </AccordionComponent>
                        </CollapsibleCustomJSWrapper>
                    )}
                    <ObjectParametersContainer>
                        <Title>Object parameters</Title>
                        <ObjectParameters
                            fields={fields}
                            formControl={control}
                            getValues={getValues}
                            useController={useObjectParametersController}
                            mroOptions={mroOptions}
                            handleInputChange={handleInputChange}
                            handleMultiPicklistChange={handleMultiPicklistChange}
                        />
                    </ObjectParametersContainer>
                </StyledForm>
            </Container>

            <ActionBar>
                <ButtonContainer>
                    <Button disabled={!isDirty || savingChanges} onClick={resetState}>
                        Discard changes
                    </Button>
                </ButtonContainer>

                <ButtonContainer>
                    <Button
                        disabled={defaultFieldsLoaded ? false : !isDirty || savingChanges || !isValid}
                        onClick={saveChanges}
                    >
                        {savingChanges ? 'Saving changes...' : 'Save changes'}
                    </Button>
                </ButtonContainer>
            </ActionBar>
            {confirmationDialogOpen && (
                <ConfirmationDialogContainer>
                    <ConfirmationDialog
                        title="Unsaved changes"
                        confirmLabel="Save changes"
                        cancelLabel="Discard changes"
                        onConfirm={saveChanges}
                        confirmDisabled={!isDirty || savingChanges || !isValid}
                        onCancel={discardChangesFromRouteBlockerPopup}
                        onClose={closeConfirmationDialog}
                        severity={SEVERITY_LEVELS.WARNING}
                    >
                        Are you sure you want to leave this window without saving changes?
                    </ConfirmationDialog>
                </ConfirmationDialogContainer>
            )}

            {showColumnsVisibilityEditor && (
                <Popover
                    open={true}
                    anchorEl={columnsVisibilityButtonRef.current}
                    onClose={() => {
                        handleOnColumnsVisibilityEditorClick(false);
                    }}
                    placement="bottom-end"
                >
                    <ColumnsVisibilityEditor columns={columns} handleVisibilityChange={handleColumnsVisibilityChange} />
                </Popover>
            )}
        </MainContainer>
    );
};

export default ObjectDefinitionContent;
