import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import useShowErrorBoundaryOnPromiseRejections from '../hooks/useShowErrorBoundaryOnPromiseRejections';

const StyledPageWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const StyledHeaderWrapper = styled(Box)``;

const StyledMainContentBoxWrapper = styled(Box)`
    flex: 1;
    overflow: auto;
`;

type Props = {
    children: ReactElement[];
};

type PropsPart = {
    children: ReactElement;
};

const Page = ({ children }: Props) => {
    useShowErrorBoundaryOnPromiseRejections();
    return <StyledPageWrapper>{children}</StyledPageWrapper>;
};

Page.Header = (props: PropsPart) => <StyledHeaderWrapper component="header">{props.children}</StyledHeaderWrapper>;

Page.Content = (props: PropsPart) => (
    <StyledMainContentBoxWrapper component="main">{props.children}</StyledMainContentBoxWrapper>
);

export default Page;
