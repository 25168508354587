export const accountsSF = [
    {
        name: 'external_record_url',
        label: 'Record URL (View Only)',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: true,
        displayType: 'AS_DETAIL',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'Name',
        label: 'Account Name',
        isMandatory: true,
        isSearchable: true,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_TITLE',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'BillingAddress',
        label: 'Billing Address',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_DETAIL',
        type: 'address',
        accuracyTypes: ['ROOFTOP', 'RANGE_INTERPOLATED'],
    },
    {
        name: 'Phone',
        label: 'Phone',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_DETAIL',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'Website',
        label: 'Website',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_DETAIL',
        type: 'text',
        isTextArea: false,
    },
];
