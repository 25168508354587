import { FieldType } from '../../../domain/IntegrationConfiguration/IntegrationConfiguration';
import { Field, Option } from '../defs';

export const useObjectParametersController = (fields: Field[]) => {
    const options: Option[] = buildOptions(fields);
    let addressFields = fields?.filter((field: Field) => field.type === FieldType.ADDRESS);
    if (addressFields.length) {
        addressFields = [{ label: '-', name: '', uuid: '', type: FieldType.ADDRESS }, ...addressFields];
    }
    const mainAddressOptions = buildOptions(addressFields);

    return {
        options,
        mainAddressOptions,
    };
};

const buildOptions = (fields: Field[] | undefined): Option[] => {
    return fields?.map((field: Field) => ({ label: field.label, value: field.name, uuid: field.uuid })) || [];
};

export type ObjectParametersControllerType = typeof useObjectParametersController;
