import { Navigate } from 'react-router-dom';
import ErrorBoundaryComponent from './common/components/Errors/ErrorBoundaryComponent';
import Login from './Login/Login';
import PageLayoutWithMenu from './common/scaffolding/PageLayoutWithMenu';
import CompaniesPage from './CompaniesPage/CompaniesPage';
import CompanyPage from './CompanyPage/CompanyPage';
import CompanyUsersMainContent from './CompanyPage/CompanyUsersPage/CompanyUsersMainContent';
import CompanySyncDataMainContent from './CompanyPage/CompanySyncDataPage/CompanySyncDataMainContent';
import AddUsersToBadgerPage from './AddUsersToBadgerPage/AddUsersToBadgerPage';
import * as React from 'react';
import { usePageLayoutWithMenuController } from './common/scaffolding/hooks/usePageLayoutWithMenuController';
import { useLoginController } from './Login/useLoginController';
import { useCompaniesMainContentController } from './CompaniesPage/hooks/useCompaniesMainContentController';
import { useCreateCompanyModalController } from './CompaniesPage/hooks/useCreateCompanyModalController';
import { useCompanyUsersMainContentController } from './CompanyPage/CompanyUsersPage/hooks/useCompanyUsersMainContentController';
import { useMultiUserSelectorController } from './CompanyPage/CompanyUsersPage/hooks/useMultiUserSelectorController';
import { useCompanyPageController } from './CompanyPage/hooks/useCompanyPageController';
import { useCompanyHeaderController } from './CompanyPage/CompanyUsersPage/hooks/useCompanyHeaderController';
import { useCompanySyncDataMainContentController } from './CompanyPage/CompanySyncDataPage/hooks/useCompanySyncDataMainContentController';
import CompanyDefinitionPage from './CompanyDefinitionPage/CompanyDefinitionPage';
import ObjectDefinitionContent from './CompanyDefinitionPage/CompanyDefinitionContent/ObjectDefinitionContent/ObjectDefinitionContent';
import { ApiDocs } from './ApiDocs/ApiDocs';
import CompanyImportReportMainContent from './CompanyPage/CompanyImportReportPage/CompanyImportReportMainContent';

const routesConfig = [
    {
        path: '/login',
        element: (
            <ErrorBoundaryComponent>
                <Login useController={useLoginController} />
            </ErrorBoundaryComponent>
        ),
    },
    {
        path: '/',
        element: <PageLayoutWithMenu useController={usePageLayoutWithMenuController} />,
        children: [
            {
                path: '/',
                element: <Navigate to="/companies" replace />,
            },
            {
                path: '/companies',
                element: (
                    <CompaniesPage
                        useCompaniesMainContentController={useCompaniesMainContentController}
                        useCreateCompanyModalController={useCreateCompanyModalController}
                    />
                ),
            },
            {
                path: '/companies/:id',
                element: (
                    <CompanyPage
                        useController={useCompanyPageController}
                        useCompanyHeaderController={useCompanyHeaderController}
                    />
                ),
                children: [
                    {
                        path: '/companies/:id/users',
                        element: (
                            <CompanyUsersMainContent
                                useCompanyUsersMainContentController={useCompanyUsersMainContentController}
                                useMultiUsersSelectorController={useMultiUserSelectorController}
                            />
                        ),
                    },
                    {
                        path: '/companies/:id/sync-data',
                        element: (
                            <CompanySyncDataMainContent
                                useCompanySyncDataMainContentController={useCompanySyncDataMainContentController}
                            />
                        ),
                    },
                    {
                        path: '/companies/:id/import-report',
                        element: <CompanyImportReportMainContent />,
                    },
                ],
            },
            {
                path: '/companies/:id/users/add-users',
                element: <AddUsersToBadgerPage />,
            },
            {
                path: '/companies/:id/field-settings',
                element: <CompanyDefinitionPage />,
                children: [
                    {
                        path: '/companies/:id/field-settings/:object',
                        element: <ObjectDefinitionContent />,
                    },
                ],
            },
            {
                path: '/api-docs',
                element: <ApiDocs />,
            },
        ],
    },
];

export default routesConfig;
