import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import Box from '@mui/material/Box';

const ProgressContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
    width: 100%;
    height: 100%;
`;

export const LoadingCircle = () => {
    return (
        <ProgressContainer>
            <CircularProgress />
        </ProgressContainer>
    );
};
