import Page from '../common/components/Page';
import CompanyDefinitionContent from './CompanyDefinitionContent/CompanyDefinitionContent';
import CompanyDefinitionHeader from './CompanyDefinitionHeader/CompanyDefinitionHeader';
import { useCompanyDefinitionPageController } from './companyDefinitionPageController';

const CompanyDefinitionPage = () => {
    useCompanyDefinitionPageController();

    return (
        <Page>
            <Page.Header>
                <CompanyDefinitionHeader />
            </Page.Header>
            <Page.Content>
                <CompanyDefinitionContent />
            </Page.Content>
        </Page>
    );
};

export default CompanyDefinitionPage;
