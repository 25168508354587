import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../commonAPIConfig';
import { mapFromAPI, mapToAPI } from './IntegrationConfigurationMapper';
import { IntegrationConfiguration } from '../../../domain/IntegrationConfiguration/IntegrationConfiguration';
import { IntegrationConfigurationSchema } from './IntegrationConfigurationSchema';

export const IntegrationConfigurationAPI = createApi({
    reducerPath: 'IntegrationConfigurationApi',
    baseQuery,
    tagTypes: ['IntegrationConfiguration'],
    endpoints: (builder) => ({
        getIntegrationConfiguration: builder.query<IntegrationConfiguration, string>({
            query: (companyId) => `/api/3/crm/companies/${companyId}/integration-configuration`,
            transformResponse: (response) => mapFromAPI(response as IntegrationConfigurationSchema),
            providesTags: (result, error, companyId) => [{ type: 'IntegrationConfiguration', companyId }],
        }),
        updateIntegrationConfiguration: builder.mutation<
            IntegrationConfiguration,
            { companyId: string; integrationConfiguration: IntegrationConfiguration }
        >({
            query: (data) => ({
                url: `/api/3/crm/companies/${data.companyId}/integration-configuration`,
                method: 'PUT',
                body: mapToAPI(data.integrationConfiguration),
            }),
            transformResponse: (response) => mapFromAPI(response as IntegrationConfigurationSchema),
            invalidatesTags: (result, error, { companyId }) => [{ type: 'IntegrationConfiguration', companyId }],
        }),
    }),
});

export const { useGetIntegrationConfigurationQuery, useUpdateIntegrationConfigurationMutation } =
    IntegrationConfigurationAPI;
