import { Controller } from 'react-hook-form';
import { MenuItem, Select } from '@mui/material';
import styled from 'styled-components';
import { Control, RegisterOptions } from 'react-hook-form/dist/types';
import { loadFontAttributes } from 'design-system';
import { Option } from '../../defs';

const StyledSelect = styled(Select)`
    width: 100%;
    height: 40px;
    margin: 8px 0;
    border-radius: 0 !important;

    &.MuiOutlinedInput-root {
        & fieldset {
            border-color: ${({ theme }) => theme.palette.Border.fields.textField.filled};
        }

        &:hover fieldset {
            border-color: ${({ theme }) => theme.palette.Border.fields.textField.focus};
        }

        &.Mui-focused fieldset {
            border-color: ${({ theme }) => theme.palette.Border.fields.textField.focus};
        }

        & .MuiSvgIcon-root {
            color: ${({ theme }) => theme.palette.Border.fields.textField.filled};
        }

        &:hover .MuiSvgIcon-root {
            color: ${({ theme }) => theme.palette.Border.fields.textField.focus};
        }

        &.Mui-focused .MuiSvgIcon-root {
            color: ${({ theme }) => theme.palette.Border.fields.textField.focus};
        }
    }
`;

const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
`;

const WrappableRow = styled(StyledRow)`
    flex-wrap: wrap;
    gap: 0 20px;
`;

const Label = styled.div`
    width: 144px;
    min-width: 144px;
    padding: 10px;
    ${({ theme }) => loadFontAttributes(theme.typography.Medium.bold)};
`;

export interface Props {
    disabled?: boolean;
    rules: RegisterOptions;
    control: Control;
    name: string;
    mroOptions: {
        label: string;
        value: string;
        uuid: string;
        options: Option[];
    }[];
    handleChange?: (value: string) => void;
}

const MainRelatedObjectType = ({ disabled, rules, control, name, mroOptions, handleChange }: Props) => {
    const renderMenuItems = (options: Option[]) =>
        options.map((option: Option) => (
            <MenuItem key={option.uuid} value={option.uuid}>
                {option.label}
            </MenuItem>
        ));

    return (
        <WrappableRow>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field: { value, onChange }, fieldState }) => {
                    return (
                        <StyledRow>
                            <Label>MRO</Label>
                            {value?.relatedObjectType !== undefined && (
                                <StyledSelect
                                    data-testid={`${name}-select-mro`}
                                    value={value.relatedObjectType}
                                    label=""
                                    disabled={disabled}
                                    onChange={(data) => {
                                        onChange({ ...value, relatedObjectType: data.target.value });
                                        if (handleChange) {
                                            handleChange(data.target.value as string);
                                        }
                                    }}
                                >
                                    {renderMenuItems(mroOptions)}
                                </StyledSelect>
                            )}
                        </StyledRow>
                    );
                }}
            />
            <Controller
                name={name}
                control={control}
                rules={{
                    validate: (value) => {
                        if (value?.relatedObjectType && !value.lookUpField) {
                            return 'This field is required if the previous field is filled';
                        }
                        return true;
                    },
                }}
                render={({ field: { value, onChange }, fieldState }) => {
                    const selectedMRO = mroOptions.find((mroOption) => mroOption.value === value?.relatedObjectType);
                    return (
                        <StyledRow>
                            <Label>MRO field</Label>
                            {value?.relatedObjectType !== undefined && (
                                <StyledSelect
                                    data-testid={`${name}-select-mro-lookupField`}
                                    value={value.lookUpField}
                                    label=""
                                    disabled={disabled}
                                    onChange={(data) => {
                                        onChange({ ...value, lookUpField: data.target.value });
                                        if (handleChange) {
                                            handleChange(data.target.value as string);
                                        }
                                    }}
                                >
                                    {renderMenuItems(selectedMRO?.options || [])}
                                </StyledSelect>
                            )}
                        </StyledRow>
                    );
                }}
            />
        </WrappableRow>
    );
};

export default MainRelatedObjectType;
