import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Column, ObjectLabelRow, ROW_COLUMN_SEPARATOR } from '../defs';

const useFormController = () => {
    const [defaultValues, setDefaultValues] = useState<{ [key: string]: string }>({});
    const { handleSubmit, control, getValues, watch, setValue, reset, formState } = useForm({
        mode: 'onChange',
        defaultValues: useMemo(() => defaultValues, [defaultValues]),
    });
    const { isDirty, isValid } = formState;
    const [keepDirty, setKeepDirty] = useState<boolean>(false);

    useEffect(() => {
        if (Object.keys(defaultValues)?.length) {
            reset(defaultValues, { keepDirty });
            setKeepDirty(false);
        }
    }, [defaultValues, reset]);

    const values = watch();
    const buildDefaultValues = (columns: Column[], rows: ObjectLabelRow[] | undefined) => {
        const defaultValues: { [key: string]: string } = {};
        rows?.forEach((row: ObjectLabelRow) => {
            columns.forEach((column: Column) => {
                const key = `${row.uuid}${ROW_COLUMN_SEPARATOR}${column.name}`;
                defaultValues[key] = row[column.name as keyof ObjectLabelRow];
                setValue(`${row.uuid}${ROW_COLUMN_SEPARATOR}${column.name}`, row[column.name as keyof ObjectLabelRow], {
                    shouldDirty: true,
                });
            });
        });
        setDefaultValues(defaultValues);
        return defaultValues;
    };
    return {
        values,
        buildDefaultValues,
        defaultValues,
        control,
        handleSubmit,
        getValues,
        isDirty,
        isValid,
    };
};

export default useFormController;
