import { Icon, IconNames } from 'design-system';
import { HeaderWithBreadcrumbs } from '../../common/components/HeaderWithBreadcrumbs';
import { styled } from 'styled-components';
import { useCompanyDefinitionHeaderController } from './companyDefinitionHeaderController';

const StyledIcon = styled(Icon)`
    color: ${({ theme }) => theme.palette.O_Primary.default};
`;

const CompanyDefinitionHeader = () => {
    const { selectedCompanyName, goToCompanies, goBackToUsers } = useCompanyDefinitionHeaderController();

    return (
        <HeaderWithBreadcrumbs
            title={'Field settings'}
            subtitle={selectedCompanyName}
            breadcrumbs={{
                path: [
                    {
                        name: 'Companies',
                        onClick: goToCompanies,
                    },
                    {
                        name: selectedCompanyName,
                        onClick: goBackToUsers,
                    },
                    {
                        name: 'Field settings',
                        onClick: () => {},
                    },
                ],
            }}
            tools={[
                <StyledIcon
                    key={'close-icon'}
                    icon={IconNames.CLOSE}
                    onClick={goBackToUsers}
                    aria-label="close-icon"
                />,
            ]}
        />
    );
};

export default CompanyDefinitionHeader;
