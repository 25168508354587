import { useLocation, useNavigate } from 'react-router-dom';
import { useSelectCompany } from '../../common/hooks/useSelectCompany';
import { useIntegrationConfigurationRepository } from '../../common/hooks/useIntegrationConfigurationRepository';
import { ObjectIntegrationConfiguration } from '../../domain/IntegrationConfiguration/IntegrationConfiguration';
import { useEffect, useState } from 'react';
import { useCompanyDefinitionRepository } from '../../common/hooks/useCompanyDefinitionRepository';
import { v4 as uuidv4 } from 'uuid';
import { ObjectLabelRow, SPECIFY_LABELS_MODAL_COLUMNS } from './ObjectDefinitionsSelectorModal/defs';
import useFormController from './ObjectDefinitionsSelectorModal/hooks/useFormController';
import CompanyDefinition, {
    BadgerObjectDefinition,
    DisplayType,
    FieldType,
    SortingOrderDirectionType,
    TextField,
} from '../../domain/CompanyDefinition/CompanyDefinition';

export type Tab = {
    label: string;
    pathIdentifier: string;
};

export type NewObjectDefinition = {
    name: string;
    label: string;
};

export const useCompanyDefinitionContentController = () => {
    const location = useLocation();
    const pathLastElement = location.pathname.split('/').slice(-1)[0];
    const navigate = useNavigate();
    const { selectedCompanyId } = useSelectCompany();
    const { companyDefinition, editCompanyDefinition } = useCompanyDefinitionRepository();
    const [rows, setRows] = useState<ObjectLabelRow[] | undefined>([]);
    const { buildDefaultValues, handleSubmit, control, isDirty, isValid } = useFormController();
    const { integrationConfiguration, isLoading, isLoadError, isSaving } = useIntegrationConfigurationRepository();

    const tabs = integrationConfiguration
        ? integrationConfiguration.objectIntegrationConfigurations.map(
              (objectIntegrationConfiguration: ObjectIntegrationConfiguration) => ({
                  label: objectIntegrationConfiguration.labelPlural,
                  pathIdentifier: objectIntegrationConfiguration.objectType,
              })
          )
        : [];

    useEffect(() => {
        if (tabs.length) {
            const index = tabs.findIndex((segment: Tab) => segment.pathIdentifier.includes(pathLastElement));
            if (index === -1) {
                navigate(`/companies/${selectedCompanyId}/field-settings/${tabs[0].pathIdentifier}`);
            }
        }
    });

    const buildRowsForCurrentObjectDefinition = () => {
        return companyDefinition?.badgerObjectDefinitions.map((badgerObjectDefinition: BadgerObjectDefinition) => {
            return {
                uuid: uuidv4(),
                badgerApiName: badgerObjectDefinition.name,
                crmLabel: badgerObjectDefinition.label,
                badgerLabel: badgerObjectDefinition.label,
                badgerLabelPlural: badgerObjectDefinition.labelPlural,
            };
        });
    };

    const createDefaultRows = (newObjectDefinitions: NewObjectDefinition[]) => {
        const objectRows: ObjectLabelRow[] = buildRowsForCurrentObjectDefinition() || [];
        const newObjectRows = newObjectDefinitions.map((newDefinition) => ({
            uuid: uuidv4(),
            badgerApiName: newDefinition.name,
            crmLabel: newDefinition.label,
            badgerLabel: '',
            badgerLabelPlural: '',
        }));

        setRows([...objectRows, ...newObjectRows]);
        buildDefaultValues(SPECIFY_LABELS_MODAL_COLUMNS, [...objectRows, ...newObjectRows]);
    };

    const findHighestOrderValue = (array: BadgerObjectDefinition[]): number => {
        return array.reduce((max, obj) => (obj['order'] > max ? obj['order'] : max), -Infinity);
    };

    const saveChanges = (specifyLabelsModalDisplayControl: {
        setOn: () => void;
        isOn: boolean;
        setOff: () => void;
    }) => {
        const submit = handleSubmit((data) => {
            if (rows?.length) {
                const fieldsRegister = buildFieldsRegister(data);
                const updatedCompanyDefinition = createUpdatedCompanyDefinition(fieldsRegister);
                editCompanyDefinition(updatedCompanyDefinition);
                specifyLabelsModalDisplayControl.setOff();
            }
        });
        submit();
    };

    const buildFieldsRegister = (data: { [p: string]: string }) => {
        const fieldsRegister: { [uuid: string]: ObjectLabelRow } = {};
        Object.entries(data).forEach(([key, value]) => {
            const [id, fieldName] = key.split('//');
            if (!fieldsRegister[id]) {
                fieldsRegister[id] = {
                    uuid: id,
                    badgerApiName: '',
                    crmLabel: '',
                    badgerLabel: '',
                    badgerLabelPlural: '',
                };
            }
            if (fieldsRegister[id]) {
                fieldsRegister[id][fieldName] = value;
            }
        });
        return fieldsRegister;
    };

    const createUpdatedCompanyDefinition = (data: { [p: string]: ObjectLabelRow }) => {
        let index = 0;
        const editedObjectDefinition = [];
        for (const rawObject in data) {
            const object = data[rawObject];
            const objectDefinition = companyDefinition?.badgerObjectDefinitions.find(
                (definition) => definition.name === object.badgerApiName
            );
            if (objectDefinition) {
                const updatedObjectDefinition = {
                    ...objectDefinition,
                    label: object.badgerLabel,
                    labelPlural: object.badgerLabelPlural,
                };
                editedObjectDefinition.push(updatedObjectDefinition);
            } else {
                const newCompanyDefinitions = buildNewCompanyDefinition(object, index);
                editedObjectDefinition.push(newCompanyDefinitions);
                index++;
            }
        }
        return {
            badgerObjectDefinitions: editedObjectDefinition,
            linkDefinitions: [],
        } as CompanyDefinition;
    };

    const buildNewCompanyDefinition = (object: ObjectLabelRow, index: number) => {
        return {
            name: object.badgerApiName,
            namePlural: `${object.badgerApiName}`,
            label: object.badgerLabel,
            labelPlural: `${object.badgerLabelPlural}`,
            isRoot: true,
            order: order + index,
            fields: [
                {
                    type: FieldType.TEXT,
                    isTextArea: false,
                    maxLength: 50,
                    name: 'example_field',
                    label: 'Example Field - change me',
                    isMandatory: false,
                    isSearchable: false,
                    isFilterable: false,
                    isColorizable: false,
                    isViewOnly: false,
                    displayOrder: 0,
                    sortingOrder: 0,
                    sortingOrderDirection: SortingOrderDirectionType.ASCENDING,
                    displayType: DisplayType.AS_DETAILS,
                } as TextField,
            ],
        };
    };

    const order = companyDefinition?.badgerObjectDefinitions.length
        ? findHighestOrderValue(companyDefinition.badgerObjectDefinitions) + 1
        : 0;

    return {
        tabs,
        isSaving,
        isLoading,
        isLoadError,
        rows,
        createDefaultRows,
        control,
        saveChanges,
        setRows,
        isDirty,
        isValid,
    };
};
export type CompanyDefinitionContentControllerType = typeof useCompanyDefinitionContentController;
