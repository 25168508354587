import { Icon, IconNames } from 'design-system';
import { useCompanyHeaderControllerType } from './CompanyUsersPage/hooks/useCompanyHeaderController';
import { HeaderWithBreadcrumbs } from '../common/components/HeaderWithBreadcrumbs';
import CompanyControlPanel from './CompanyControlPanel';
import useCompanyControlPanelController from './hooks/useCompanyControlPanelController';

const CompanyHeader = ({ useController }: { useController: useCompanyHeaderControllerType }) => {
    const { selectedCompanyName, selectedCompanyId, goBackToCompanies, goToFieldSettings } = useController();

    return (
        <>
            <HeaderWithBreadcrumbs
                title={selectedCompanyName}
                subtitle={`Company ID: ${selectedCompanyId}`}
                breadcrumbs={{
                    path: [
                        {
                            name: 'Companies',
                            onClick: goBackToCompanies,
                        },
                        {
                            name: selectedCompanyName,
                            onClick: () => {},
                        },
                    ],
                }}
                tools={[
                    <Icon
                        key={'wheel-icon'}
                        icon={IconNames.WHEEL}
                        onClick={goToFieldSettings}
                        aria-label="wheel-icon"
                    />,
                ]}
            />

            <CompanyControlPanel useController={useCompanyControlPanelController} />
        </>
    );
};

export default CompanyHeader;
