import Page from '../common/components/Page';
import CompanyHeader from './CompanyHeader';
import { Outlet } from 'react-router-dom';
import { useCompanyHeaderControllerType } from './CompanyUsersPage/hooks/useCompanyHeaderController';
import { useCompanyPageControllerType } from './hooks/useCompanyPageController';
import { useSelectedCompany } from './hooks/useSelectedCompany';
import { NotFoundError } from 'design-system';
import { useEffect } from 'react';
import { useCompanyUsersRepository } from '../common/hooks/useCompanyUsersRepository';

const CompanyPage = ({
    useController,
    useCompanyHeaderController,
}: {
    useController: useCompanyPageControllerType;
    useCompanyHeaderController: useCompanyHeaderControllerType;
}) => {
    const { useUpdateSelectedCompanyIdToMatchURL } = useController();
    useUpdateSelectedCompanyIdToMatchURL();
    const { exists } = useSelectedCompany();
    const { fetch } = useCompanyUsersRepository();

    useEffect(() => {
        fetch();
    }, []);

    return exists ? (
        <Page>
            <Page.Header>
                <CompanyHeader useController={useCompanyHeaderController} />
            </Page.Header>
            <Page.Content>
                <Outlet />
            </Page.Content>
        </Page>
    ) : (
        <NotFoundError action={() => (window.location.href = '/companies')} />
    );
};

export default CompanyPage;
